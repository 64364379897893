<template>
  <b-card title="Select Machine">
    <b-col cols="4">
      <b-button variant="primary" :to="{ name: 'view-fetch' }" size="sm">
        <feather-icon size="1x" icon="ArrowLeftIcon" /> Back to View Fetch
        Detail
      </b-button>
    </b-col>
    <br />
    <b-col cols="12">
      <br />
      <b-row>
        <b-col>
          <span> Machine Name : {{ form.machine.name }}{{ params }} </span>
          <b-modal
            id="promptAddEdit"
            ref="modal"
            v-model="promptAddEdit"
            title="Select Machine"
          >
            <b-form-group label="Machine List" label-for="form-machine">
              <v-select
                v-model="form.machine"
                placeholder="----- Select Machine -----"
                :options="selectMachine"
                label="label"
                :clearable="false"
              />
            </b-form-group>

            <template #modal-footer>
              <b-button size="md" variant="success" @click="save">
                Save
              </b-button>
              <b-button
                size="md"
                variant="danger"
                @click="promptAddEdit = false"
              >
                Cancel
              </b-button>
            </template>
          </b-modal>
          <b-button
            variant="primary"
            class="float-right mb-2"
            @click="addMachine"
          >
            Select Machine
          </b-button>
          <b-table
            :items="form.item"
            :fields="fieldsItem"
            small
            head-variant="dark"
            responsive
            stacked="md"
          >
            <template v-slot:cell(actions)="row">
              >
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-col>

    <br />

    <b-col cols="12">
      <b-button variant="primary" class="float-right" @click="machineSelected">
        Save
      </b-button>
    </b-col>
  </b-card>
</template>

<script>
import axios from "@/axios";
import { defaultMaxListeners } from "events";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
  },
  props: ["id"],
  data() {
    return {
      params: "",
      form: {
        item: [],
        props: {},
        status: "Machine Selected",
        machine: {},
      },
      items: [],
      fieldsItem: [
        { key: "name", label: "Name" },
        { key: "type", label: "Type" },
      ],
      promptAddEdit: false,
    };
  },

  methods: {
    clearForm() {
      this.form = {};
    },

    addMachine() {
      this.promptAddEdit = true;
      // this.title = 'Add Sales Order';
      this.addEdit = "Add";
      // this.clearFields();
    },

    save() {
      this.form.item = [];
      this.promptAddEdit = false;
      var stations = this.$store.getters["plant/getStations"].find((x) => {
        x = this.form.machine;
        x.hardwares.map((y) => {
          this.form.item.push({ name: y.name, type: y.type });
        });
        return x;
      });
      return stations;
    },

    machineSelected() {
      var dataFetch = this.$store.getters["fetch/getFetchId"];

      var send = {
        fetchID: this.id,
        machineID: this.form.machine.id,
        status: this.form.status,
      };

      this.$store
        .dispatch("fetch/selectMachine", send)
        .then(async () => {
          this.clearForm();
          await this.$router.push("/machine");
          await this.$bvToast.toast("Successfully Select Machine", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          console.log({ err });
          this.$bvToast.toast(err.response.data.error, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        });

      this.$store
        .dispatch("joborder/jobOrderTimestamp", {
          id: dataFetch.JobOrderID,
          timestamp: "MDE",
        })
        .then((x) => {
          this.babyModal = false;
          this.$bvToast.toast("End Picking Mama Drum", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          this.$bvToast.toast(err, {
            title: "Error",
            variant: "warning",
            solid: true,
          });
        });
    },
  },

  created() {
    document.title = "Select Machine | Tai Sin - Commerce";
  },
  computed: {
    selectMachine() {
      return this.$store.getters["plant/getStations"].map((el) => {
        return {
          ...el,
          label: el.name,
        };
      });
    },
  },

  mounted() {
    this.$store.dispatch("user/fetchUser").catch((err) => console.log(err));
    this.$store.dispatch("order/fetchOrder").catch((err) => console.log(err));
    this.$store.dispatch("plant/getStations").catch((err) => console.log(err));
    this.$store
      .dispatch("fetch/getFetchId", { id: this.id })
      .catch((err) => console.log(err));
  },
};
</script>

<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: textfield;
  appearance: textfield;
}
</style>
